import React from 'react';
import Layout from '../components/Layout';
import { withPrefix } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

//Components
import ColoringList from '../components/ColoringList';

const NotFoundPage = () => (
	<Layout>
		<section className="hero">
			<div className="hero-body">
				<div className="container has-text-centered">
					<h1 className="title is-1">Not found</h1>
					<p>The content you are looking for is not available.</p>

					<AniLink className="button is-download" to="/" paintDrip hex="#FFE600">
						Back Home
					</AniLink>
				</div>
			</div>
		</section>

		<div className={`image-loader loaded has-text-centered`}>
			<img src={`${withPrefix('/')}img/keyvisual/bart.png`} width="200" alt="Bart" />
		</div>

		<section className="section coloring-list-small">
			<div className="container">
				<h2 className="title is-2 has-text-centered">Discover more coloring</h2>

				<div className="columns is-centered is-paddingless">
					<div className="column is-8">
						<ColoringList random={true} itemsCount={4} columnWidth={3} responsive={false} />
					</div>
				</div>
			</div>
		</section>
	</Layout>
)

export default NotFoundPage;
